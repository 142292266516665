import { inject, Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { CommentService } from '@shared/services/data/comment/comment.service';
import { UserManagerService } from '@shared/services/user-manager/user-manager.service';
import { HtmlImageService } from '@shared/services/data/html-image/html-image.service';
import { ModelActionData } from '@shared/services/communication/shared-subjects/shared-subjects-models';
import { CardApiService } from '@shared/api/api-loop/services';
import { SharedSubjects } from '@shared/services/communication/shared-subjects/shared-subjects';
import { TranslationsSmartCommandsApiService } from '@shared/modules/translations/data-access/translations-data-access/translations-smart-commands-api.service';
import { TranslationFormat } from '@shared/modules/smart-commands/common/translations/constants/translation-format';
import { TranslateResponse } from '@shared/modules/smart-commands/common/translations/interfaces/responses';
import { ClipboardService } from '@shared/shared/clipboard/clipboard-service/clipboard.service';
import { ClipboardEvents } from '@shared/shared/clipboard/common/constants/clipboard-events';
import {
  ClipboardCommentKeys,
  createCommentClipboardData
} from '@shared/modules/comments/common/helpers/comment-clipboard-value';
import {
  CommentFormComponent,
  CommentFormMailType
} from '@shared/modules/comments/components/comment-form/comment-form.component';
import { LOOP_DYNAMIC_COMPONENT_NAME } from '@shared/modules/loop/components/loop-dynamic-components';
import { ModalFormService } from '@shared/forms/shell/modal-form/modal-form.service';
import { ReplyType } from '@shared/modules/comments/common/constants/reply-type';
import { ConfirmService } from '@shared/shared/confirm/common/confirm.service';
import { ResourceBaseType } from '@shared/api/common/contatns/ResourceBaseType';
import { CommentDraftActions } from '@shared/modules/comments/common/constants/comment-draft-actions';
import { MailCommentOperationsModule } from '@shared/modules/comments/components/mail-comment/mail-comment-operations/mail-comment-operations.module';
import { MailCommentSendMailOperationsService } from '@shared/modules/comments/components/mail-comment/mail-comment-send-mail-operations/mail-comment-send-mail-operations.service';
import { CommentMail } from '@shared/api/api-loop/models/comment-mail';
import { HandleTimeMethod } from '@shared/modules/smart-commands/common/handle-time/constants/handle-time-method';
import { HandleTimeSmartCommandsApiService } from '@shared/modules/comments/data-access/handle-time-data-access/handle-time-smart-commands-api.service';
import { CardType, HandleTimeSettings, SubscriptionLicense } from '@shared/api/api-loop/models';
import {
  CommentDraftModel,
  CommentMailModel,
  CommentTemplateModel
} from '@dta/shared/models-api-loop/comment/comment.model';
import { map, switchMap, take, tap } from 'rxjs/operators';
import { combineLatest, defer, Observable, of } from 'rxjs';
import { HistoryAndSignatureHelper } from '@dta/shared/utils/history-and-signature/history-and-signature.helper';
import { ConversationModel } from '@dta/shared/models-api-loop/conversation-card/conversation/conversation.model';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { PrintService } from '@dta/ui/components/common/print/print.service';
import { MainMenuService } from '@dta/ui/components/menu/menu.service';
import { TrackingService } from '@dta/shared/services/tracking/tracking.service';
import { cardNameToSubject } from '@dta/ui/pipes/subject/card-name-to-subject';
import { StorageKey, StorageService } from '@dta/shared/services/storage/storage.service';

interface BaseActionParams {
  conversation: ConversationModel;
  replyToComment: CommentMailModel;
  commentDrafts?: CommentDraftModel[];
  groupId?: string;
}

interface TranslateActionParams extends BaseActionParams {
  from_language?: string;
  to_language: string;
}

interface ReplyActionParams extends BaseActionParams {
  replyType: 'replyAll' | 'replyOne';
  content?: string;
  isForceSplit?: boolean;
}

interface ForwardActionParams extends BaseActionParams {
  forceSplit?: boolean;
}

interface PrintActionParams extends BaseActionParams {
  html?: string;
}

@UntilDestroy()
@Injectable({
  providedIn: MailCommentOperationsModule
})
export class MailCommentOperationsService {
  private readonly commentService: CommentService = inject(CommentService);
  private readonly userManagerService: UserManagerService = inject(UserManagerService);
  private readonly historyAndSignatureHelper: HistoryAndSignatureHelper = inject(HistoryAndSignatureHelper);
  private readonly printService: PrintService = inject(PrintService);
  private readonly httpClient: HttpClient = inject(HttpClient);
  private readonly menuService: MainMenuService = inject(MainMenuService);
  private readonly htmlImageService: HtmlImageService = inject(HtmlImageService);
  private readonly cardApiService: CardApiService = inject(CardApiService);
  private readonly trackingService: TrackingService = inject(TrackingService);
  private readonly translationsSmartCommandsApiService: TranslationsSmartCommandsApiService = inject(
    TranslationsSmartCommandsApiService
  );
  private readonly clipboardServiceService: ClipboardService = inject(ClipboardService);
  private readonly modalFormService: ModalFormService = inject(ModalFormService);
  private readonly confirmService: ConfirmService = inject(ConfirmService);
  private readonly mailCommentSendMailOperationsService: MailCommentSendMailOperationsService = inject(
    MailCommentSendMailOperationsService
  );
  private readonly handleTimeSmartCommandsApiService: HandleTimeSmartCommandsApiService = inject(
    HandleTimeSmartCommandsApiService
  );
  private readonly storageService: StorageService = inject(StorageService);

  private readonly handleTimeSettings: HandleTimeSettings = (
    this.storageService.getParsedItem(
      this.storageService.getKey(this.userManagerService.getCurrentUserEmail(), StorageKey.userTopPriorityLicense)
    ) as SubscriptionLicense
  )?.handleTimeSettings;

  createCommentMail$(
    commentParams: CommentFormMailType,
    options?: {
      shouldHandleStopDraftTime?: boolean;
      conversationId?: string;
    }
  ): Observable<CommentMail> {
    return this.mailCommentSendMailOperationsService.createCommentMail$(commentParams, options);
  }

  deleteDraft$(conversation: ConversationModel, commentDraftId?: string): Observable<boolean> {
    this.trackingService.trackUserClick(this.userManagerService.getCurrentUserEmail(), 'MailComment', 'Delete draft', {
      conversationId: conversation.cardId,
      draftId: conversation.privateDraft?.id
    });

    return this.confirmService
      .confirm({
        confirmMessage: 'Discard',
        declineMessage: 'Cancel',
        message: 'Are you sure you want to delete this draft?',
        description: 'Discarding a draft will delete this draft and all messages related to it',
        confirmButton: {
          fill: 'color',
          color: 'danger'
        }
      })
      .pipe(
        switchMap(() => {
          const draft = conversation.privateDraft;
          return this.cardApiService
            .Card_DeleteCardDraft({ cardDraft: draft }, this.userManagerService.getCurrentUserEmail())
            .pipe(map(() => true));
        }),
        tap(deleted => {
          if (deleted) {
            if (
              commentDraftId &&
              conversation.cardType === CardType.CARD_SHARED &&
              this.handleTimeSettings?.isEnabled
            ) {
              this.handleTimeSmartCommandsApiService
                .sendHandleTimeStatus$(HandleTimeMethod.draft_deleted, {
                  comment_draft_id: commentDraftId,
                  group_id: conversation.findGroupInShareList()?.id,
                  conversation_id: conversation.cardId
                })
                .pipe(take(1))
                .subscribe();
            }

            this.sendDraftDelete(conversation.privateDraft?.id);
          }
        })
      );
  }

  private sendDraftDelete(cardDraftId?: string): void {
    if (!cardDraftId) {
      return;
    }

    let modelActionData = new ModelActionData();
    modelActionData.resource = ResourceBaseType.commentDraft;
    modelActionData.action = CommentDraftActions.draftDeleted;
    modelActionData.data = {
      draftCardId: cardDraftId
    };

    SharedSubjects._modelAction$.next(modelActionData);
  }

  get currentUserEmail(): string {
    return this.userManagerService.getCurrentUserEmail();
  }

  forwardAsAttachment(params: BaseActionParams): void {
    if (params.commentDrafts && params.commentDrafts.length) {
      this.openExistingDraft(params);
      return;
    }

    this.trackingService.trackUserClick(
      this.userManagerService.getCurrentUserEmail(),
      'MailComment',
      'Forward as attachment',
      {
        conversationId: params.conversation.cardId,
        commentId: params.replyToComment?.id
      }
    );

    this.modalFormService.create<CommentFormMailType, CommentFormComponent, any>({
      modalTitle$: of(`Fwd: ${params.conversation.name}`),
      componentId: LOOP_DYNAMIC_COMPONENT_NAME.CommentFormComponent,
      windowIdentifier: params.conversation.cardId,
      componentParams: {
        previousComment: params.replyToComment,
        replyType: ReplyType.forward,
        conversationGroupId: params.groupId,
        conversationId: params.conversation.cardId,
        initialFromIds: [params.conversation.importedByContact?.id, params.groupId],
        isForwardAsAttachment: true,
        defaults: {
          subject: `Fwd: ${params.conversation.name}`
        }
      },
      useCustomLayout: true,
      modalWidth: '1000px',
      afterSubmit$: (value: CommentFormMailType) => {
        return this.mailCommentSendMailOperationsService.createCommentMail$(value);
      },
      observer: () => {
        //noop
      }
    });
  }

  forwardAction(params: ForwardActionParams): void {
    if (params.commentDrafts && params.commentDrafts.length) {
      this.openExistingDraft(params);
      return;
    }

    this.trackingService.trackUserClick(this.userManagerService.getCurrentUserEmail(), 'MailComment', 'Forward', {
      conversationId: params.conversation.cardId,
      commentId: params.replyToComment?.id
    });

    this.modalFormService.create<CommentFormMailType, CommentFormComponent, any>({
      modalTitle$: of(`Fwd: ${params.conversation.name}`),
      componentId: LOOP_DYNAMIC_COMPONENT_NAME.CommentFormComponent,
      windowIdentifier: params.conversation.cardId,
      componentParams: {
        previousComment: params.replyToComment,
        replyType: ReplyType.forward,
        conversationGroupId: params.groupId,
        conversationId: params.conversation.cardId,
        initialFromIds: [params.conversation.importedByContact?.id, params.groupId],
        isForceSplit: params.forceSplit,
        defaults: {
          attachments: params.replyToComment.attachments?.resources ?? [],
          subject: `Fwd: ${cardNameToSubject(params.replyToComment.parent?.name)}`
        }
      },
      useCustomLayout: true,
      modalWidth: '1000px',
      afterSubmit$: (value: CommentFormMailType) => {
        return this.mailCommentSendMailOperationsService.createCommentMail$(value);
      },
      observer: () => {
        //noop
      }
    });
  }

  replyAction(params: ReplyActionParams): void {
    if (params.commentDrafts && params.commentDrafts.length) {
      this.openExistingDraft(params);
      return;
    }

    this.trackingService.trackUserClick(this.userManagerService.getCurrentUserEmail(), 'MailComment', 'Reply', {
      conversationId: params.conversation.cardId,
      commentId: params.replyToComment?.id,
      replyType: params.replyType
    });

    this.modalFormService.create<CommentFormMailType, CommentFormComponent, any>({
      modalTitle$: of(`Re: ${params.conversation.name}`),
      componentId: LOOP_DYNAMIC_COMPONENT_NAME.CommentFormComponent,
      windowIdentifier: params.conversation.cardId,
      componentParams: {
        previousComment: params.replyToComment,
        replyType: params.replyType === 'replyAll' ? ReplyType.replyAll : ReplyType.reply,
        conversationGroupId: params.groupId,
        conversationId: params.conversation.cardId,
        initialFromIds: [params.conversation.importedByContact?.id, params.groupId],
        isForceSplit: params.isForceSplit,
        initialContent: params.content
      },
      useCustomLayout: true,
      modalWidth: '1000px',
      afterSubmit$: (value: CommentFormMailType) => {
        return this.mailCommentSendMailOperationsService.createCommentMail$(value, {
          shouldHandleStopDraftTime: true,
          conversationId: params.conversation.cardId
        });
      },
      observer: () => {
        //noop
      }
    });
  }

  printCommentMail(params: PrintActionParams): void {
    this.trackingService.trackUserClick(this.userManagerService.getCurrentUserEmail(), 'MailComment', 'Print', {
      conversationId: params.conversation.cardId,
      commentId: params.replyToComment?.id
    });

    this.getCommentHtml$(params.replyToComment)
      .pipe(take(1), untilDestroyed(this))
      .subscribe(commentBody => {
        let printableComment = this.historyAndSignatureHelper.prepareCommentForPrint(
          commentBody,
          params.replyToComment,
          params.conversation.name
        );
        this.printService.print(printableComment);
      });
  }

  translateCommentMail(params: TranslateActionParams): Observable<TranslateResponse> {
    return this.translationsSmartCommandsApiService.translateText$({
      body: params.replyToComment.body.content,
      format: TranslationFormat.html,
      to_language: params.to_language,
      from_language: params.from_language
    });
  }

  downloadCommentMail(params: BaseActionParams): void {
    this.trackingService.trackUserClick(
      this.userManagerService.getCurrentUserEmail(),
      'MailComment',
      'Download comment',
      {
        conversationId: params.conversation.cardId,
        commentId: params.replyToComment?.id
      }
    );

    this.commentService
      .downloadCommentContent(
        this.userManagerService.getCurrentUserEmail(),
        params.replyToComment.id,
        params.conversation.name
      )
      .pipe(take(1), untilDestroyed(this))
      .subscribe();
  }

  storeCommentToClipboard(params: BaseActionParams): void {
    this.clipboardServiceService.storeDataToClipboard(
      ClipboardEvents.copyEml,
      createCommentClipboardData({
        [ClipboardCommentKeys.commentId]: params.replyToComment.id,
        [ClipboardCommentKeys.fileName]: `${params.conversation.name}_${params.replyToComment.id}`
      })
    );
  }

  expandComment(): void {
    this.menuService.toggleSidebar();
  }

  getCommentHtml$(comment: CommentMailModel | CommentTemplateModel): Observable<string> {
    let signedLink = comment.getBodyContentFetchLink();
    return this.httpClient.get(signedLink, { responseType: 'arraybuffer' }).pipe(
      map((response: ArrayBuffer) => {
        return new TextDecoder().decode(response);
      }),
      switchMap(body => {
        return this.replaceImgCidWIthFilePaths(comment, body).pipe(
          tap(bodyHtml => {
            comment.body.content = bodyHtml;
          })
        );
      })
    );
  }

  replaceImgCidWIthFilePaths(comment: CommentMailModel | CommentTemplateModel, body: string): Observable<string> {
    return this.htmlImageService.replaceImgCidWithFilePaths(
      this.userManagerService.getCurrentUserEmail(),
      body,
      comment.getAttachments()
    );
  }

  private openExistingDraft(params: BaseActionParams): void {
    defer(() => {
      if (params.commentDrafts.some(commentDraft => commentDraft.sendLaterDate)) {
        return this.confirmService
          .confirmOrDecline({
            message: 'Scheduled reply in progress',
            description: 'You already have a reply scheduled to be sent later. Do you want to edit it?',
            confirmMessage: 'Edit Scheduled Reply',
            declineMessage: 'Keep as Scheduled'
          })
          .pipe(
            switchMap(deleteSendLater => {
              const commentDraftsWithSendLater = params.commentDrafts.filter(draft => draft.sendLaterDate);
              if (!deleteSendLater) {
                return of(false);
              }

              return combineLatest(
                commentDraftsWithSendLater.map(commentDraft => {
                  return this.cardApiService
                    .Card_CancelSendLaterCardDraft(
                      {
                        cardDraftId: commentDraft.parent.id
                      },
                      this.userManagerService.getCurrentUserEmail()
                    )
                    .pipe(take(1));
                })
              ).pipe(map(() => true));
            })
          );
      }

      return of(true);
    })
      .pipe(take(1))
      .subscribe(openDraft => {
        if (!openDraft) {
          return;
        }

        this.modalFormService.create<CommentFormMailType, CommentFormComponent, any>({
          componentId: LOOP_DYNAMIC_COMPONENT_NAME.CommentFormComponent,
          windowIdentifier: params.conversation.cardId,
          componentParams: {
            previousComment: params.replyToComment,
            conversationGroupId: params.groupId,
            conversationId: params.conversation.cardId,
            initialFromIds: [params.conversation.importedByContact?.id, params.groupId],
            draftCommentId: params.commentDrafts[0]?.id
          },
          useCustomLayout: true,
          modalWidth: '1000px',
          afterSubmit$: (value: CommentFormMailType) => {
            return this.mailCommentSendMailOperationsService.createCommentMail$(value, {
              shouldHandleStopDraftTime: true,
              conversationId: params.conversation.cardId
            });
          },
          observer: () => {
            //noop
          }
        });
      });
  }
}
