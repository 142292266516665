import * as _ from 'lodash';
import { BaseModel } from '../base/base.model';
import {
  CardBase,
  CardShared,
  CommentBase,
  CommentBody,
  CommentChat,
  CommentDraft,
  CommentMail,
  CommentTemplate,
  IFrameContent,
  ListOfResourcesOfContactBase,
  ListOfResourcesOfTag,
  ListOfTags,
  ResourceBase,
  SharedTagBase,
  TagType,
  User
} from '@shared/api/api-loop/models';
import { CollectionGroupName } from '@shared/models/constants/collection.names';
import { ContactBaseModel, ContactModel, GroupModel, UserModel } from '../contact/contact.model';
import { FileModel } from '../file.model';
import { ContactBase } from '@shared/api/api-loop/models/contact-base';
import { ListOfResourcesOfFile } from '@shared/api/api-loop/models/list-of-resources-of-file';
import { Tag } from '@shared/api/api-loop/models/tag';
import {
  CardAppointmentModel,
  CardBaseModel,
  CardDraftModel,
  CardMailModel
} from '../conversation-card/card/card.model';
import {
  SharedTagBaseModel,
  SharedTagLabelModel,
  SharedTagMentionModel,
  SharedTagReactionModel,
  SharedTagSystemModel,
  StaticSharedTagIds
} from '../shared-tag/shared-tag.model';
import { ListOfTagsModel, TagLabelModel, TagModel } from '../tag.model';
import { UnfurlData } from '@shared/models/unfurl/unfurl.model';
import { CONSTANTS } from '@shared/models/constants/constants';
import { FileStorageService } from '@shared/services/file-storage/file-storage.service';
import { Observable, of } from 'rxjs';
import { flatMap } from 'rxjs/operators';
import { EMLFile, MSGFile } from '@dta/ui/services/file-association-handler/common/interfaces/mail-file';
import { ChannelType } from '@shared/modules/comments/common/constants/channel-type';

export type CommentModel = CommentMailModel | CommentChatModel | CommentTemplateModel | CommentDraftModel;
export type CommentExtraData = CommentChatExtraData | CommentMailExtraData;

export abstract class CommentBaseModel extends BaseModel implements CommentBase {
  static collectionName: CollectionGroupName = CollectionGroupName.Comment;

  created: string;
  author: User;
  parent: CardBase;
  attachments?: ListOfResourcesOfFile;
  snippet: string;
  tags: ListOfTags;
  sharedTags?: ListOfTags;
  isFolderingActionUpdate?: boolean;

  _ex?: CommentBaseExtraData;
  _ui?: CommentBaseViewData;

  static create(doc: CommentBase): CommentModel {
    if (!doc || !doc.$type) {
      throw new Error(`Invalid $type given ${JSON.stringify(doc)}`);
    }
    if (doc instanceof CommentBaseModel) {
      return <any>doc;
    }

    switch (doc.$type) {
      case CommentDraftModel.type:
        return new CommentDraftModel(doc);
      case CommentTemplateModel.type:
        return new CommentTemplateModel(doc);
      case CommentMailModel.type:
        return new CommentMailModel(doc);
      case CommentChatModel.type:
        return new CommentChatModel(doc);
      default:
        break;
    }
  }

  static createList(docs: CommentBase[]): CommentModel[] {
    return _.map(docs, doc => CommentBaseModel.create(doc));
  }

  static buildSnippet(comment: CommentModel): CommentSnippet {
    let snippet = comment.snippet;

    if (comment instanceof CommentChatModel && comment.hasSharedTagId(StaticSharedTagIds.DELETED_ID)) {
      snippet = 'Message has been deleted';
    }

    if (comment instanceof CommentDraftModel) {
      snippet = snippet || 'No content';
    }

    return {
      _id: comment._id,
      created: comment.created,
      author: comment.author,
      snippet,
      isArchived: comment.hasTagId(TagType.ARCHIVE)
    };
  }

  static buildCommentUnread(comment: CommentModel): CommentUnread {
    let contactId = comment.author.id;

    // comment might not have ExtraData when it was synced by unread-sync
    if (!comment._ex || comment._ex.isGroupComment) {
      let group = comment.getShareList().find(contact => contact.$type === GroupModel.type);
      contactId = group ? group.id : contactId;
    }

    return {
      contactId: contactId,
      tags: comment.tags
    };
  }

  createEmptySharedTagList() {
    let parent: ResourceBase = this.toResourceBase();

    let tags: ListOfResourcesOfTag = {
      resources: [],
      offset: 0,
      size: 0,
      totalSize: 0
    };

    let sharedTags: ListOfTags = {
      $type: ListOfTagsModel.type,
      revision: '0',
      tags: tags,
      parent: parent
    };

    this.sharedTags = sharedTags;
  }

  hasSharedTagReaction(tag: SharedTagReactionModel): boolean {
    if (!this.sharedTags || !this.sharedTags.tags) {
      return false;
    }

    return _.some(this.sharedTags.tags.resources, (_tag: SharedTagBaseModel) => {
      return (
        _tag.$type === SharedTagReactionModel.type &&
        _tag.name === tag.name &&
        (<SharedTagReactionModel>_tag).userId === tag.userId
      );
    });
  }

  addOrRemoveReactionSharedTag(reactionTag: SharedTagReactionModel) {
    if (this.hasSharedTagReaction(reactionTag)) {
      this.removeSharedReactionTag(reactionTag);
    } else {
      this.setSharedTag(reactionTag);
    }
  }

  setSharedTag(sharedTag: SharedTagBase) {
    if (!this.sharedTags || !this.sharedTags.tags) {
      this.createEmptySharedTagList();
    }

    this.sharedTags.tags.resources.push(sharedTag);
    this.sharedTags.tags.size += 1;
    this.sharedTags.revision = (parseInt(this.sharedTags.revision, 10) + 1).toString();
  }

  removeSharedTag(sharedTag: SharedTagBase, options?: { skipRevisionBump: boolean }) {
    if (!this.sharedTags?.tags?.resources) {
      return;
    }

    _.remove(this.sharedTags.tags.resources, tag => {
      return tag.name === sharedTag.name;
    });

    this.sharedTags.tags.size -= 1;

    if (options?.skipRevisionBump) {
      return;
    }
    this.sharedTags.revision = (parseInt(this.sharedTags.revision, 10) + 1).toString();
  }

  removeSharedReactionTag(sharedTag: SharedTagReactionModel) {
    _.remove(this.sharedTags.tags.resources, tag => {
      return (
        tag.$type === SharedTagReactionModel.type &&
        (<SharedTagReactionModel>tag).userId === sharedTag.userId &&
        tag.name === sharedTag.name
      );
    });

    this.sharedTags.tags.size -= 1;
    this.sharedTags.revision = (parseInt(this.sharedTags.revision, 10) + 1).toString();
  }

  hasAttachments(): boolean {
    return this.attachments && !_.isEmpty(this.attachments.resources);
  }

  hasMention(): boolean {
    return this.$type === CommentChatModel.type && this.hasSharedTagType(SharedTagMentionModel.type);
  }

  hasFailedAttachments(): boolean {
    if (!this.hasAttachments()) {
      return false;
    }
    return this.attachments.resources.some((file: FileModel) => file._ex.syncStatus.failed);
  }

  hasTags(): boolean {
    return this.tags && this.tags.tags && (!_.isEmpty(this.tags.tags.resources) || this.tags.revision !== '0');
  }

  hasSharedTags(): boolean {
    return this.sharedTags && this.sharedTags.tags && !_.isEmpty(this.sharedTags.tags.resources);
  }

  hasSharedTagType(type: string): boolean {
    if (!this.hasSharedTags()) {
      return false;
    }

    return _.some(this.sharedTags.tags.resources, { $type: type });
  }

  hasSharedTagId(_id: string): boolean {
    if (!this.hasSharedTags()) {
      return false;
    }

    return _.some(this.sharedTags.tags.resources, { id: _id });
  }

  hasTagId(id: string): boolean {
    if (!this.hasTags()) {
      return false;
    }

    return _.some(this.tags.tags.resources, { id: id });
  }

  setTags(newTags: Tag[]) {
    if (!this.tags) {
      // Create missing tags list
      let listOfTags: ListOfResourcesOfTag = {
        resources: [],
        offset: 0,
        size: 0,
        totalSize: 0
      };

      let tags: ListOfTags = {
        $type: ListOfTagsModel.type,
        revision: '0',
        tags: listOfTags,
        parent: this.toResourceBase()
      };

      this.tags = tags;
    }

    this.tags.tags.resources = newTags;
  }

  getTags(): Tag[] {
    return this.hasTags() ? this.tags.tags.resources : [];
  }

  getSharedTags(): Tag[] {
    return this.hasSharedTags() ? this.sharedTags.tags.resources : [];
  }

  getAttachments(): FileModel[] {
    return FileModel.createList(this.getResources(this.attachments));
  }

  addAttachments(attachmentsToAdd: FileModel[]) {
    if (_.isEmpty(attachmentsToAdd)) {
      return;
    }

    if (_.isEmpty(this.attachments)) {
      this.attachments = {
        offset: 0,
        size: 0,
        totalSize: 0,
        resources: []
      } as ListOfResourcesOfFile;
    }

    // Filter out undefined attachments (for safety)
    attachmentsToAdd = _.filter(attachmentsToAdd, f => !_.isEmpty(f));

    this.attachments.resources = _.uniqBy([...this.getResources(this.attachments), ...attachmentsToAdd], 'id');
  }

  hasNonInlineAttachments(): boolean {
    return this.getAttachments().some(file => !('hidden' in file) || !file.hidden);
  }

  getNonInlineAttachments(): FileModel[] {
    return this.getAttachments().filter(file => !('hidden' in file) || !file.hidden);
  }

  getInlineAttachments(): FileModel[] {
    return this.getAttachments().filter(file => file.hidden);
  }

  contactsToReducedForm() {
    for (let prop in this) {
      switch (prop) {
        case 'to':
        case 'cc':
        case 'bcc':
        case 'replyTo':
        case 'shareList':
          _.forEach(this[prop]['resources'], contact => {
            ContactBaseModel.toReducedForm(contact);
          });
          break;
        case 'author':
          ContactBaseModel.toReducedForm(this[prop]);
          break;
        default:
          break;
      }
    }
  }

  populateWithContacts(contacts: ContactModel[]) {
    for (let prop in this) {
      switch (prop) {
        case 'to':
        case 'cc':
        case 'bcc':
        case 'replyTo':
        case 'shareList':
          // Case when comment template
          if (!this[prop]) {
            break;
          }

          let populatedList = _.map(this[prop]['resources'], contact => {
            // If contact is not synced, return current
            if (!contact.id) {
              return contact;
            }
            let fullContact = Object.assign(
              {},
              _.find(contacts, c => c.id === contact.id)
            );

            // Preserve display name if present
            return Object.assign({}, fullContact, contact);
          });
          this[prop]['resources'] = populatedList;
          break;
        case 'author':
          // If contact is not synced, return current
          if (!this.author.id) {
            break;
          }
          let fullAuthor = Object.assign(
            {},
            _.find(contacts, c => c.id === this.author.id)
          );
          this.author = _.merge(fullAuthor, this.author);
          break;
        default:
          break;
      }
    }
  }

  getAllContacts(): ContactBase[] {
    if (this.author) {
      return [this.author];
    }

    return [];
  }

  getSearchableContent(): string[] {
    let contactsAsModels = ContactBaseModel.createList(this.getAllContacts());
    let cardContacts = _.flatMap(contactsAsModels, (contact: ContactBaseModel) => {
      return contact.getSearchableContent();
    });

    return _.filter([...cardContacts, this.name, this.snippet], item => !_.isEmpty(item));
  }

  addTagToTagList(tag: TagModel) {
    if (!this.hasTags()) {
      return;
    }

    this.tags.tags.resources = _.uniqBy([...this.getTags(), tag], 'id');
  }
}
export class CommentMailModel extends CommentBaseModel implements CommentMail {
  static type: string = 'CommentMail';
  readonly $type: string = CommentMailModel.type;
  static mutableProperties: string[] = ['body.contentSignedLink'];

  from?: ContactBase;
  to: ListOfResourcesOfContactBase;
  cc: ListOfResourcesOfContactBase;
  bcc: ListOfResourcesOfContactBase;
  replyTo: ListOfResourcesOfContactBase;
  forwardedCopy: boolean;
  body: CommentBody;
  originalAuthor: User;
  channelType?: string;

  _ex: CommentMailExtraData;
  isLastMailComment?: boolean;
  isFirstMailComment?: boolean;

  constructor(data?: CommentMail) {
    super(data);

    // Fallback to empty snippet
    this.snippet = this.snippet || '';
  }

  toCommentBase(): CommentMail {
    let comment = <CommentMailModel>this.cloneDeep();

    delete comment._ex;
    delete comment._ui;

    comment.author = ContactBaseModel.buildFromBaseAsReference(comment.author);
    comment.to.resources = _.map(comment.to.resources, contact => {
      return ContactBaseModel.buildFromBaseAsReference(contact);
    });
    comment.cc.resources = _.map(comment.cc.resources, contact => {
      return ContactBaseModel.buildFromBaseAsReference(contact);
    });
    comment.bcc.resources = _.map(comment.bcc.resources, contact => {
      return ContactBaseModel.buildFromBaseAsReference(contact);
    });

    return <CommentMail>comment.toObject();
  }

  supportsHtmlBody(): boolean {
    return this.body.availableMimeTypes && _.includes(this.body.availableMimeTypes.resources, MimeType.html);
  }

  hasBodyContentFetchLink(): boolean {
    return !_.isEmpty(this.getBodyContentFetchLink());
  }

  getBodyContentFetchLink(): string {
    return this.body?.contentSignedLink;
  }

  hasBody(): boolean {
    return this.body && !_.isUndefined(this.body.content) && !this.hasBodySavedOnDisc();
  }

  hasBodySavedOnDisc(): boolean {
    // Use text when determining if "body save on disc" HTML is set as content
    // because body can be processed (like color shifting)
    return this.body?.content?.includes(`>${CONSTANTS.BODY_SAVED_ON_DISC_TEXT}<`);
  }

  getBody(): string {
    return this.hasBody() ? this.body.content : '';
  }

  getSearchableBody(): string {
    return this.getBody().replace(/<(.|\n)*?>/gi, '');
  }

  hasCc(): boolean {
    return this.cc && this.cc.resources && this.cc.resources.length > 0;
  }

  hasBcc(): boolean {
    return this.bcc && this.bcc.resources && this.bcc.resources.length > 0;
  }

  hasHtmlBody(): boolean {
    return this.hasBody() && this.body.mimeType === MimeType.html;
  }

  getReplyTo(): ContactBase[] {
    return this.getResources(this.replyTo);
  }

  getShareList(includeBcc: boolean = false): ContactBase[] {
    let to = this.getResources(this.to);
    let cc = this.getResources(this.cc);

    if (includeBcc) {
      let bcc = this.getResources(this.bcc);
      return _.unionBy(to, cc, bcc, 'id');
    }

    return _.unionBy(to, cc, 'id');
  }

  getAllContacts(): ContactBase[] {
    let to = this.getResources(this.to);
    let cc = this.getResources(this.cc);
    let bcc = this.getResources(this.bcc);
    let replyTo = this.getResources(this.replyTo);

    let joined = _.unionBy([this.author], replyTo, bcc, to, cc, 'id');

    // Remove undefined entries
    return _.filter(joined, contact => contact !== undefined);
  }

  getShareListWithAuthor(): ContactBase[] {
    let to = this.getResources(this.to);
    let cc = this.getResources(this.cc);

    return _.unionBy([this.author], to, cc, 'id');
  }

  getShareListWithAuthorOrReplyTo(): ContactBase[] {
    let to = this.getResources(this.to);
    let cc = this.getResources(this.cc);
    let replyTo = this.getResources(this.replyTo);

    let author = !_.isEmpty(replyTo) ? replyTo : [this.author];

    // Get unique by id and filter out undefined values
    return _.filter(_.unionBy(author, to, cc, 'id'), user => !_.isUndefined(user));
  }

  buildCardFromComment(): CardMailModel | CardAppointmentModel {
    let card =
      this.parent.$type === CardMailModel.type ? new CardMailModel(this.parent) : new CardAppointmentModel(this.parent);
    let shareList = _.unionBy(this.getShareListWithAuthor(), contact => contact.id);

    card.name = this.name;
    card.shareList = ContactBaseModel.createListOfResources(shareList);
    card.comments = CommentBaseModel.createListOfResources([this]);
    card._id = CardBaseModel.create(this.parent)._id;

    return <CardMailModel>card;
  }

  isMeToMe(currentUserEmail: string): boolean {
    let authorIsCurrentUser = this.author.email === currentUserEmail; // Current user is author
    let toListIsEmpty = !this.to || _.isEmpty(this.to.resources); // To list is empty in case of me to me
    let authorAloneOnToList =
      this.to.resources.length === 1 && // In case it is not
      this.to.resources[0].$type === UserModel.type &&
      (<UserModel>this.to.resources[0]).email === currentUserEmail;

    return authorIsCurrentUser && (toListIsEmpty || authorAloneOnToList);
  }

  setExPassthroughData(propertyName: string, propertyValue: any) {
    if (!this._ex) {
      this._ex = <CommentMailExtraData>{};
    }

    this._ex[propertyName] = propertyValue;
  }

  getSearchableContent(): string[] {
    let searchableContent = super.getSearchableContent();

    if (this.hasBody()) {
      searchableContent.push(this.getSearchableBody());
    }

    return _.filter(searchableContent, item => !_.isEmpty(item));
  }

  loadBodyFromDisc(fileStorageService: FileStorageService): Observable<CommentMailModel> {
    if (this.hasBodySavedOnDisc()) {
      return fileStorageService.readFile('body/' + this._id).pipe(
        flatMap(body => {
          if (!_.isEmpty(body)) {
            try {
              this.body.content = new TextDecoder('utf-8').decode(body);
            } catch (e) {
              // JSON parse failed, ignore
              console.log(
                'error in composer reply component failed json parse or decode string err=' + JSON.stringify(e)
              );
            }
          }

          return of(this);
        })
      );
    }

    return of(this);
  }
}

export class CommentChatModel extends CommentBaseModel implements CommentChat {
  static type: string = 'CommentChat';

  readonly $type: string = CommentChatModel.type;

  comment: string;
  shareList: ListOfResourcesOfContactBase;
  quoteCommentId?: string;
  quotedComment?: CommentChatModel;
  iFrameContent?: IFrameContent;
  isQuotedByIds?: string[];

  _ex?: CommentChatExtraData;
  _ui?: CommentChatViewData;

  constructor(data?: CommentChat) {
    super(data);

    // Fallback to empty snippet
    this.snippet = this.snippet || '';
  }

  toCommentBase(): CommentChat {
    let comment = <CommentChatModel>this.cloneDeep();

    delete comment._ex;
    delete comment._ui;

    comment.author = BaseModel.buildFromBaseAsReference(comment.author);
    comment.shareList.resources = _.map(comment.shareList.resources, contact => {
      return ContactBaseModel.buildFromBaseAsReference(contact);
    });

    if (
      comment.parent &&
      !comment.parent.id &&
      !(<CardShared>comment.parent).isLive &&
      comment.parent.$type !== CardDraftModel.type
    ) {
      delete comment.parent;
    }

    return <CommentMail>comment.toObject();
  }

  supportsHtmlBody(): boolean {
    return false;
  }

  hasBodyContentFetchLink(): boolean {
    return false;
  }

  getBodyContentFetchLink(): string {
    return undefined;
  }

  hasBody(): boolean {
    return !_.isNil(this.comment);
  }

  hasReactions(): boolean {
    if (!this.hasSharedTags()) {
      return false;
    }

    return _.some(this.sharedTags.tags.resources, (_tag: SharedTagBaseModel) => {
      return _tag.$type === SharedTagReactionModel.type;
    });
  }

  getShareList(includeBcc = false): ContactBase[] {
    return this.getResources(this.shareList);
  }

  getShareListWithAuthor(): ContactBase[] {
    let shareList = this.getResources(this.shareList);
    return _.unionBy([this.author], shareList, 'id');
  }

  getAllContacts(): ContactBase[] {
    let shareList = this.getResources(this.shareList);

    let joined = _.unionBy([this.author], shareList, 'id');

    // Remove undefined entries
    return _.filter(joined, contact => contact !== undefined);
  }

  getQuotedAttachmentResources(): ResourceBase[] {
    if (this.quotedComment && this.quotedComment.attachments && !_.isEmpty(this.quotedComment.attachments.resources)) {
      return this.quotedComment.attachments.resources;
    } else {
      return [];
    }
  }

  getReactionSharedTags(): SharedTagReactionModel[] {
    if (!this.hasReactions()) {
      return [];
    }

    return _.filter(this.sharedTags.tags.resources, (_tag: Tag) => {
      return _tag.$type === SharedTagReactionModel.type;
    }) as SharedTagReactionModel[];
  }

  getSearchableContent(): string[] {
    return _.filter([...super.getSearchableContent(), this.comment], item => !_.isEmpty(item));
  }

  isSystemMessage(): boolean {
    return this.hasTagId(TagType.SYSTEMMESSAGE) || this.hasSharedTagType(SharedTagSystemModel.type);
  }
}

export class CommentTemplateModel extends CommentBaseModel implements CommentTemplate {
  static type: string = 'CommentTemplate';

  readonly $type: string = CommentTemplateModel.type;

  body: CommentBody;
  shareList: ListOfResourcesOfContactBase;
  description: string;

  _ex?: CommentBaseExtraData;
  _ui?: CommentBaseViewData;

  constructor(data?: CommentTemplate) {
    super(data);

    // Fallback to empty snippet
    this.snippet = this.snippet || '';
  }

  static buildTemplateComment(
    content: string,
    description: string,
    shareList: ListOfResourcesOfContactBase
  ): CommentTemplateModel {
    // Build template comment
    let commentTemplate = new CommentTemplateModel();
    commentTemplate.description = description;
    commentTemplate.shareList = shareList;

    let body: CommentBody = {
      content: content,
      $type: 'CommentBody',
      mimeType: MimeType.htmlRaw
    };
    commentTemplate.body = body;

    return commentTemplate;
  }

  getShareList(includeBcc = false): ContactBase[] {
    return this.shareList ? this.shareList.resources : [];
  }

  supportsHtmlBody(): boolean {
    return this.body.availableMimeTypes && _.includes(this.body.availableMimeTypes.resources, MimeType.html);
  }

  hasBodyContentFetchLink(): boolean {
    return !_.isEmpty(this.getBodyContentFetchLink());
  }

  getBodyContentFetchLink(): string {
    return this.body?.contentSignedLink;
  }

  hasBody(): boolean {
    return this.body && !_.isEmpty(this.body.content);
  }

  hasHtmlBody(): boolean {
    return this.hasBody() && this.body.mimeType === MimeType.html;
  }

  getShareListWithAuthor(): ContactBase[] {
    return this.getResources(this.shareList);
  }

  getAllContacts(): ContactBase[] {
    let shareList = this.shareList ? this.getResources(this.shareList) : [];

    let joined = _.unionBy([this.author], shareList, 'id');

    // Remove undefined entries
    return _.filter(joined, contact => contact !== undefined);
  }

  toCommentBase(): CommentTemplate {
    let comment = <CommentTemplateModel>this.cloneDeep();

    delete comment._ex;
    delete comment._ui;

    comment.author = BaseModel.buildFromBaseAsReference(comment.author);
    comment.shareList.resources = _.map(comment.shareList.resources, contact => {
      return ContactBaseModel.buildFromBaseAsReference(contact);
    });

    return <CommentMail>comment.toObject();
  }
}

export class QuoteCommentModel {
  static type: string = 'Quote';

  readonly $type: string = QuoteCommentModel.type;

  created: string;
  author: User;
  attachments?: ListOfResourcesOfFile;
  snippet: string;
  comment: string;
  id: string;
  sharedTags?: ListOfTags;
  revision: string;

  _ui?: CommentQuoteViewData;

  constructor(data?: CommentChat) {
    if (data && data.$type !== CommentChatModel.type) {
      throw new Error(`Invalid $type for QuoteCommentModel, should be ${CommentChatModel.type} but is ${data.$type}`);
    }

    if (data) {
      _.assign(this, data);
    }
  }

  hasSharedTags(): boolean {
    return this.sharedTags && this.sharedTags.tags && !_.isEmpty(this.sharedTags.tags.resources);
  }

  hasSharedTagId(_id: string): boolean {
    if (!this.hasSharedTags()) {
      return false;
    }

    return _.some(this.sharedTags.tags.resources, { id: _id });
  }
}

export class CommentDraftModel extends CommentMailModel implements CommentDraft {
  static type: string = 'CommentDraft';

  readonly $type: string = CommentDraftModel.type;
  static mutableProperties: string[] = ['attachments'];

  contentFileId: string;
  sendAs?: string;
  sendAsGroupId?: string;
  draftOnCardId?: string;
  sendLaterDate?: Date;

  _ex: CommentDraftExtraData;

  constructor(data?: CommentDraft) {
    super(data);

    this.sendLaterDate = data?.sendLaterDateTime ? new Date(data.sendLaterDateTime) : undefined;
  }

  hasSyncableChanges(comparedComment: CommentDraft): boolean {
    if (!comparedComment) {
      return true;
    }

    let commentA = this.minimize();
    let commentB = new CommentDraftModel(comparedComment).minimize();

    return !_.every(Object.keys(commentA), property => {
      switch (property) {
        // Simple equal
        case 'forwardedCopy':
        case 'contentFileId':
        case 'sendAs':
        case 'sendAsGroupId':
        case 'name':
          return commentA[property] === commentB[property];
        // Equal by id
        case 'from':
        case 'originalAuthor':
        case 'author':
        case 'parent':
          return commentA[property]?.id === commentB[property]?.id;
        // Equal by ids in resource list
        case 'to':
        case 'cc':
        case 'bcc':
        case 'replyTo':
          let idsInA = _.map((commentA[property] && commentA[property]['resources']) || [], r => r.id);
          let idsInB = _.map((commentB[property] && commentB[property]['resources']) || [], r => r.id);
          return _.isEmpty(_.xor(idsInA, idsInB));
        // Equal by ids in tag list
        case 'tags':
        case 'sharedTags':
          let tagInA = _.map((commentA[property] && commentA[property]['tags']['resources']) || [], r => r.id);
          let tagInB = _.map((commentB[property] && commentB[property]['tags']['resources']) || [], r => r.id);
          return _.isEmpty(_.xor(tagInA, tagInB));
        // Equal by hashed in resource list
        case 'attachments':
          let hashesInA = _.map((commentA[property] && commentA[property]['resources']) || [], r => r.hash);
          let hashesInB = _.map((commentB[property] && commentB[property]['resources']) || [], r => r.hash);
          return _.isEmpty(_.xor(hashesInA, hashesInB));
        default:
          return true;
      }
    });
  }

  private minimize(): CommentDraftModel {
    // Remove properties with empty resources
    let commentCopy = _.cloneDeep(this);
    for (let prop in commentCopy) {
      // Remove empty resources
      if (commentCopy[prop] && commentCopy[prop]['resources']?.length === 0) {
        delete commentCopy[prop];
      }

      // Remove undefined values
      if (_.isUndefined(commentCopy[prop])) {
        delete commentCopy[prop];
      }
    }

    return commentCopy;
  }
}

export interface CommentBaseExtraData {
  errorSending: boolean;
  errorMessage: string;
  isGroupComment: boolean;
  sourceResourceId: string;
  hasMentions: boolean;
}

export interface CommentMailExtraData extends CommentBaseExtraData {
  useSendAs: boolean;
  isBodyParsed: boolean;
  sendAsGroupId: string;
  bodyComplexityIndex: number;
  missingDisplayName: boolean;
  undoData?: UndoData; // We need this data to revert send back to draft

  createdFromDraftCardId?: string;
  createdFromDraftCommentId?: string;
  shouldSplitOnSend?: boolean;
}

export interface CommentDraftExtraData extends CommentMailExtraData {
  linkedMailCard?: string;
  saveAndClose?: boolean;
  unSyncedBodyChanges?: boolean; // Passthrough flag for dirty body
  sendAsContact?: User;
}

export interface CommentChatExtraData extends CommentBaseExtraData {
  unfurled?: UnfurlData;
  bodyProcessed: boolean;
  editedLocally: boolean;

  // Id of email card loopin was created from
  // (in case of first loopin chat comment)
  sourceResourceId: string;

  // Label added to email card that triggered conversion to loopin
  labels?: (SharedTagLabelModel | TagLabelModel)[];
}

export interface CommentBaseViewData {
  attachmentsSynchronized: boolean;
  isUnread: boolean;
  isVisiblyUnread: boolean;
  isForwardedCopy: boolean;
  recalculateCount: number;

  // If comment is trashed, personal tag is set to DELETED.
  // We tread CARD with last comment trashed as trashed
  isTrashed: boolean;

  // If comment is deleted, shared tag is set to DELETED
  // We tread that COMMENT as deleted and has no affect on card state
  isDeleted: boolean;

  // Optional UI fields
  expanded?: boolean;
  scrollInitially?: boolean;
  isBeforeDivider?: boolean;
}

export interface CommentChatViewData extends CommentBaseViewData {
  previousSharelist?: ContactBase[];
  summaryText: string;
  hasBubbleContent?: boolean;
  reactionDictionary?: { [reaction: string]: ReactionData };
  isEdited?: boolean;
}

export interface CommentQuoteViewData {
  isDeleted: boolean;
  isEdited: boolean;
}

export enum MimeType {
  bbtag = 'application/vnd.loop.text.plain.bbtag',
  html = 'text/html', // Was 'text/html-stripped' but setting it to 'text/html' turn on RAW HTML
  htmlRaw = 'text/html'
}

export interface CommentSnippet {
  _id: string;
  created: string;
  author: User;
  snippet: string;
  isArchived: boolean;
}

export interface CommentUnread {
  // either author.id or group.id if it's an group comment
  contactId: string;
  tags: ListOfTags;
}

export interface UndoData {
  undoSent: boolean;
  body: string;
  history: string;
  signatureHtml: string;
  isForward: boolean;
}

export interface ReactionEventData {
  comment: CommentChatModel;
  emoji: string;
}

export interface ReactionData {
  reactorNames: string[];
  isMyReaction: boolean;
}

export function isMailComment(comment: CommentBase): comment is CommentMailModel {
  return comment.$type === CommentMailModel.type;
}

export function isChatComment(comment: CommentBase): comment is CommentChatModel {
  return comment.$type === CommentChatModel.type;
}

export function isDraftComment(comment: CommentBase): comment is CommentDraftModel {
  return comment.$type === CommentDraftModel.type;
}

export function isTemplateComment(comment: CommentBase): comment is CommentTemplate {
  return comment.$type === CommentTemplateModel.type;
}

export function isSystemComment(comment: CommentBaseModel): boolean {
  return (
    !isMailComment(comment) &&
    !isCommentDeleted(comment) &&
    (!!comment?.hasTagId(TagType.SYSTEMMESSAGE) || !!comment?.hasSharedTagType(SharedTagSystemModel.type))
  );
}

export function isIframeComment(comment: CommentBaseModel): boolean {
  return isChatComment(comment) && !!comment.iFrameContent;
}

export function isCommentDeleted(comment: CommentBaseModel): boolean {
  return comment.hasSharedTagId(StaticSharedTagIds.DELETED_ID);
}
