import { ComponentRef, EventEmitter, inject, Injectable, Injector, ViewContainerRef } from '@angular/core';
import { ComponentPortal } from '@angular/cdk/portal';
import { ConnectedPosition, Overlay, OverlayRef } from '@angular/cdk/overlay';
import { CustomDropdownComponentConfiguration } from '@shared/ui/dropdown/custom-dropdown/custom-dropdown.directive';
import { EnumObjectValue } from '@shared/utils/common/types';
import { CustomDropdownBase } from '@shared/ui/dropdown/custom-dropdown/custom-dropdown-base';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { filter, take, tap } from 'rxjs/operators';
import { fromEvent, Observable, Subscription } from 'rxjs';
import { KeyboardShortcutsListenerService } from '@dta/ui/services/keyboard-shortcuts-listener/keyboard-shortcuts-listener.service';
import { KeyboardActionModeType } from '@dta/shared/models/keyboard-shortcut.model';

export const CustomDropdownPlacement = {
  bottom_start: 'bottom_start',
  bottom_end: 'bottom_end',
  top_start: 'top_start',
  top_end: 'top_end'
} as const;

const CUSTOM_DROPDOWN_POSITIONS: Record<keyof typeof CustomDropdownPlacement, ConnectedPosition> = {
  [CustomDropdownPlacement.bottom_start]: {
    originX: 'start',
    originY: 'bottom',
    overlayX: 'start',
    overlayY: 'top'
  },
  [CustomDropdownPlacement.bottom_end]: {
    originX: 'start',
    originY: 'bottom',
    overlayX: 'end',
    overlayY: 'top'
  },
  [CustomDropdownPlacement.top_start]: {
    originX: 'start',
    originY: 'top',
    overlayX: 'start',
    overlayY: 'bottom'
  },
  [CustomDropdownPlacement.top_end]: {
    originX: 'start',
    originY: 'top',
    overlayX: 'end',
    overlayY: 'bottom'
  }
} as const;

interface OpenDropdownParams<C> {
  dropdownConfiguration: CustomDropdownComponentConfiguration<C>;
  viewContainerRef: ViewContainerRef;
  preferredPlacement?: EnumObjectValue<typeof CustomDropdownPlacement>;
}

@UntilDestroy()
@Injectable()
export class CustomDropdownService {
  private readonly injector: Injector = inject(Injector);
  private readonly overlay: Overlay = inject(Overlay);

  private overlayRef: OverlayRef;
  private closeDropdownSubscription?: Subscription;

  openDropdown<C>({ dropdownConfiguration, viewContainerRef, preferredPlacement }: OpenDropdownParams<C>): {
    componentRef: ComponentRef<any>;
    dropdownSubmit$: Observable<any> | null;
  } {
    if (this.overlayRef) {
      this.overlayRef.dispose();
    }

    const preferredPosition = CUSTOM_DROPDOWN_POSITIONS[preferredPlacement ?? CustomDropdownPlacement.bottom_start];
    const positionStrategy = this.overlay
      .position()
      .flexibleConnectedTo(viewContainerRef.element)
      .withPositions([preferredPosition, ...Object.values(CUSTOM_DROPDOWN_POSITIONS)]);

    this.overlayRef = this.overlay.create({
      positionStrategy: positionStrategy,
      panelClass: 'loop-custom-dropdown-overlay',
      hasBackdrop: true,
      backdropClass: 'cdk-overlay-transparent-backdrop',
      disposeOnNavigation: true
    });

    this.injector.get(KeyboardShortcutsListenerService).setMode(KeyboardActionModeType.DROPDOWN);
    const componentRef = this.overlayRef.attach(
      new ComponentPortal(
        dropdownConfiguration.component.component,
        null,
        dropdownConfiguration.component.parentInjector ?? this.injector
      )
    );
    componentRef.changeDetectorRef.detectChanges();

    const backdropClickToCloseSubscription = this.overlayRef.backdropClick().subscribe(() => {
      this.closeDropdown();
    });

    const escapeKeySubscription = fromEvent<KeyboardEvent>(document, 'keydown')
      .pipe(filter(event => event.key === 'Escape'))
      .subscribe(() => this.closeDropdown());

    componentRef.onDestroy(() => {
      backdropClickToCloseSubscription?.unsubscribe();
      escapeKeySubscription?.unsubscribe();
      this.closeDropdown();
    });
    this.observeCloseDropdown(componentRef);
    this.setComponentParams(componentRef, dropdownConfiguration.componentParams);

    if (!(componentRef.instance instanceof CustomDropdownBase)) {
      return {
        componentRef: componentRef,
        dropdownSubmit$: null
      };
    }

    return {
      componentRef: componentRef,
      dropdownSubmit$:
        componentRef.instance?.itemClick && componentRef.instance.itemClick instanceof EventEmitter
          ? componentRef.instance.itemClick.pipe(
              take(1),
              tap(() => this.closeDropdown())
            )
          : null
    };
  }

  private observeCloseDropdown(componentRef: ComponentRef<any>): void {
    this.closeDropdownSubscription?.unsubscribe();
    this.closeDropdownSubscription = componentRef?.instance?.closeDropdown
      ?.pipe(untilDestroyed(this), take(1))
      .subscribe(() => {
        this.closeDropdown();
      });
  }

  setComponentParams<C>(componentRef: ComponentRef<any>, componentParams: Partial<C>): void {
    if (!componentParams) {
      return;
    }

    if (!componentRef?.instance) {
      return;
    }

    Object.entries(componentParams).map(([key, value]) => {
      componentRef.instance[key] = value;
    });
  }

  private closeDropdown(): void {
    this.injector.get(KeyboardShortcutsListenerService).goToPreviousMode(KeyboardActionModeType.DROPDOWN);
    this.closeDropdownSubscription?.unsubscribe();
    this.overlayRef?.dispose();
  }
}
