import { ChangeDetectorRef, ComponentRef, Injector, Renderer2, ViewContainerRef } from '@angular/core';
import { ScrollDispatcher, ViewportRuler } from '@angular/cdk/overlay';
import { CdkContextMenuTrigger, CdkMenuTrigger, CdkMenuTriggerBase } from '@angular/cdk/menu';
import { BrowserDropdownRendererComponent } from '@shared/ui/dropdown/browser/browser-menu-renderer/browser-menu-renderer.component';
import { DropdownItem } from '@shared/ui/dropdown/interfaces/dropdown-item';
import { CDK_POSITIONS } from '@shared/ui/dropdown/browser/cdk-positions';
import { DropdownInitializer } from '@shared/ui/dropdown/interfaces/dropdown-initializers';
import { filterEmptyActions } from '@shared/ui/dropdown/rxjs/operators/filter-empty-actions';
import { DropdownTrigger } from '@shared/ui/dropdown/enumerators/dropdown-trigger';
import { debounceTime, filter, map, switchMap, tap } from 'rxjs/operators';
import { fromEvent, merge, of } from 'rxjs';

export const createDropdownRendererComponentRef = (
  injector: Injector
): ComponentRef<BrowserDropdownRendererComponent> =>
  injector.get(ViewContainerRef).createComponent(BrowserDropdownRendererComponent, {
    injector
  });

export const createDropdownRendererComponentRefItemsSetter =
  (
    cdkMenuTriggerFor: CdkMenuTriggerBase,
    dropdownMenuComponentRef: ComponentRef<BrowserDropdownRendererComponent>
  ): ((items: readonly DropdownItem[]) => void) =>
  items => {
    dropdownMenuComponentRef.instance.setItems(items, {
      isDropdownOpened: cdkMenuTriggerFor.isOpen()
    });
    cdkMenuTriggerFor.menuTemplateRef = dropdownMenuComponentRef.instance.templateRef;
  };

export const createDropdownPositionSetter = (
  cdkMenuTriggerFor: CdkMenuTriggerBase
): ((position: 'left' | 'right') => void) => {
  const left = Object.values(CDK_POSITIONS['left']);
  const right = Object.values(CDK_POSITIONS['right']);

  // The default value is left
  cdkMenuTriggerFor.menuPosition = [...left, ...right];

  return position => {
    if (position === 'left') {
      cdkMenuTriggerFor.menuPosition = [...left, ...right];
    } else {
      cdkMenuTriggerFor.menuPosition = [...right, ...left];
    }
  };
};

export const closeMenuWithAnimation = (cdkMenuTriggerFor: CdkMenuTrigger, injector: Injector): void => {
  if (!cdkMenuTriggerFor.isOpen()) {
    return;
  }

  const el = cdkMenuTriggerFor.getMenu()?.nativeElement;
  if (el) {
    // We need to set inline styles of 'transform-origin' manually, because the css class which sets the `--transform-origin` variable
    // is removed too soon and if we wouldn't add styles manually, the animation won't show because of the removed class.
    injector
      .get(Renderer2)
      .setStyle(el, 'transform-origin', getComputedStyle(el).getPropertyValue('--transform-origin'));
  }

  cdkMenuTriggerFor.close();
};

export const clickDropdownInitializer: DropdownInitializer = (injector, hostElement) => {
  const cdkMenuTriggerFor = new CdkMenuTrigger();
  const dropdownMenuComponentRef = createDropdownRendererComponentRef(injector);
  let isDisabled = false;
  let isOpened = false;

  injector.get(Renderer2).setProperty(hostElement.nativeElement, 'cdkMenuTriggerFor', cdkMenuTriggerFor);

  return {
    setItems: createDropdownRendererComponentRefItemsSetter(cdkMenuTriggerFor, dropdownMenuComponentRef),
    // keyArrowDown$:
    dropdown$: merge(
      // For some reason cdkMenuTriggerFor.isOpen() returns false when element is still opened or Trigger for is clicked
      // Thus we are debounce closed event and use our own isOpened variable
      cdkMenuTriggerFor.closed.pipe(
        debounceTime(300),
        tap(() => {
          isOpened = false;
        })
      ),
      fromEvent<MouseEvent>(hostElement.nativeElement, 'click').pipe(
        filter(() => !isDisabled),
        tap(event => event.stopPropagation()),
        filterEmptyActions(() => dropdownMenuComponentRef.instance.availableItems$),
        map(() => {
          if (cdkMenuTriggerFor.isOpen() || isOpened) {
            closeMenuWithAnimation(cdkMenuTriggerFor, injector);
          } else {
            dropdownMenuComponentRef.instance.onOpen();
            cdkMenuTriggerFor.open();
            isOpened = true;
          }
        })
      )
    ),
    setPosition: createDropdownPositionSetter(cdkMenuTriggerFor),
    setIsDisabled: disabled => (isDisabled = disabled),
    focus: () => {
      cdkMenuTriggerFor.getMenu()?.nativeElement?.focus();
    }
  };
};

const contextDropdownInitializer: DropdownInitializer | null = (injector, hostElement, options) => {
  if (options.disabledOnBrowser) {
    return null;
  }

  const cdkMenuTriggerFor = new CdkContextMenuTrigger();
  const dropdownMenuComponentRef = createDropdownRendererComponentRef(injector);
  const viewportRuler = injector.get(ViewportRuler);

  injector.get(Renderer2).setProperty(hostElement.nativeElement, 'cdkMenuTriggerFor', cdkMenuTriggerFor);

  return {
    setItems: createDropdownRendererComponentRefItemsSetter(cdkMenuTriggerFor, dropdownMenuComponentRef),
    dropdown$: fromEvent<MouseEvent>(hostElement.nativeElement, 'contextmenu').pipe(
      switchMap(event => {
        return of(event).pipe(filterEmptyActions(() => dropdownMenuComponentRef.instance.availableItems$));
      }),
      map(event => cdkMenuTriggerFor._openOnContextMenu(event)),
      switchMap(() => {
        const initialScrollPosition = viewportRuler.getViewportScrollPosition().top;
        return injector
          .get(ScrollDispatcher)
          .scrolled(10)
          .pipe(
            filter(() => cdkMenuTriggerFor.isOpen()),
            map(() => viewportRuler.getViewportScrollPosition().top),
            filter(currentScrollPosition => Math.abs(currentScrollPosition - initialScrollPosition) > 10),
            tap(() => {
              cdkMenuTriggerFor.close();
              cdkMenuTriggerFor.injector.get(ChangeDetectorRef).detectChanges();
            })
          );
      }),
      map(() => undefined)
    ),
    setPosition: () => {
      // noop
    },
    setIsDisabled: isDisabled => (cdkMenuTriggerFor.disabled = isDisabled),
    focus: () => {
      cdkMenuTriggerFor.menuTemplateRef?.elementRef?.nativeElement?.focus();
    }
  };
};

export const BrowserDropdownInitializers = {
  [DropdownTrigger.click]: clickDropdownInitializer,
  [DropdownTrigger.context]: contextDropdownInitializer
} as const;
