import {
  DropdownActionItem,
  DropdownHelpTextItem,
  DropdownItem,
  DropdownNestedItem,
  DropdownRouterLinkItem
} from '../interfaces/dropdown-item';

export const isDropdownActionItem = (item: DropdownItem): item is DropdownActionItem =>
  typeof (item as DropdownActionItem).action === 'function';

export const isDropdownRouterLinkItem = (item: DropdownItem): item is DropdownRouterLinkItem =>
  typeof (item as DropdownRouterLinkItem).routerLink === 'function';

export const isDropdownHelpTextItem = (item: DropdownItem): item is DropdownHelpTextItem =>
  !!(item as DropdownHelpTextItem).isHelpText;

export const isDropdownNestedItem = (item: DropdownItem): item is DropdownNestedItem =>
  !!(item as DropdownNestedItem).childItems;
