<ng-template #cdkMenuTpl>
  <div @slideMotion class="dropdown-menu" cdkMenu>
    <ng-container *ngIf="availableItems$ | async; let items">
      <span cdkMenuItem></span>
      <div class="flex flex-col" tabindex="0">
        <ng-container *ngFor="let item of items">
          <ng-container
            *ngIf="item.isVisibleFn?.() ?? true"
            [ngTemplateOutlet]="
              (item | isDropdownHelpTextItem)
                ? buttonItemTpl
                : (item | isDropdownRouterLinkItem)
                  ? anchorItemTpl
                  : buttonItemTpl
            "
          ></ng-container>
          <ng-template #buttonItemTpl>
            <ng-template [ngIf]="(item | asDropdownDelimiterItemPipe).isDelimiter" [ngIfElse]="buttonTpl">
              <hr class="m-0" />
            </ng-template>

            <ng-template #buttonTpl>
              <ng-container *ngIf="item | isDropdownNestedItem; else actionItemTpl">
                <loop-dropdown-menu-renderer
                  #dropdownMenuRendererTpl
                  [availableItems]="(item | asDropdownNestedItem).childItems"
                ></loop-dropdown-menu-renderer>
                <a
                  [cdkMenuItemDisabled]="(item | asDropdownHelpTextItem).isHelpText"
                  [class.dropdown-menu__item--danger]="item.isDanger"
                  [class.dropdown-menu__item--selected]="item.isSelected"
                  [class.dropdown-menu__item--helptext]="(item | asDropdownHelpTextItem).isHelpText"
                  [class.bold]="item.isBold"
                  [cdkMenuTriggerFor]="dropdownMenuRendererTpl.templateRef"
                  (cdkMenuItemTriggered)="handleItemClick(item)"
                  class="dropdown-menu__item"
                  cdkMenuItem
                >
                  <ng-container *ngTemplateOutlet="actionButtonContentTpl; context: { $implicit: item }"></ng-container>
                </a>
              </ng-container>
              <ng-template #actionItemTpl>
                <a
                  [cdkMenuItemDisabled]="(item | asDropdownHelpTextItem).isHelpText"
                  [class.dropdown-menu__item--danger]="item.isDanger"
                  [class.dropdown-menu__item--selected]="item.isSelected"
                  [class.dropdown-menu__item--helptext]="(item | asDropdownHelpTextItem).isHelpText"
                  [class.bold]="item.isBold"
                  (cdkMenuItemTriggered)="handleItemClick(item)"
                  class="dropdown-menu__item"
                  cdkMenuItem
                >
                  <ng-container *ngTemplateOutlet="actionButtonContentTpl; context: { $implicit: item }"></ng-container>
                </a>
              </ng-template>
            </ng-template>
          </ng-template>
          <ng-template #anchorItemTpl>
            <a
              [routerLink]="item | asDropdownRouterLinkItem | dropdownMenuItemRouterLink"
              [queryParams]="(item | asDropdownRouterLinkItem).queryParams ?? undefined"
              [fragment]="(item | asDropdownRouterLinkItem).routerLinkFragment ?? undefined"
              [class.dropdown-menu__item--danger]="item.isDanger"
              [class.dropdown-menu__item--selected]="item.isSelected"
              [class.bold]="item.isBold"
              class="dropdown-menu__item"
              cdkMenuItem
            >
              <div class="flex items-center">
                <span>{{ item.title }}</span>
                <svg-comp *ngIf="item.icon" [src]="item.icon"></svg-comp>
              </div>
            </a>
          </ng-template>
        </ng-container>
      </div>
    </ng-container>
  </div>
  <ng-template #actionButtonContentTpl let-item>
    <div class="flex gap-x-half items-center">
      <div>
        <loop-icon *ngIf="item.icon" [name]="item.icon" height="14px" width="14px"></loop-icon>
      </div>
      <div class="flex items-center justify-between gap-x flex-1">
        <span>{{ item.title }}</span>
        <ng-container *ngIf="item | isDropdownNestedItem; else shortcutTpl">
          <div>
            <loop-icon class="rotate-90 m-0" name="angle-up" height="14px" width="14px"></loop-icon>
          </div>
        </ng-container>
        <ng-template #shortcutTpl>
          <span class="shortcut" *ngIf="item.shortcut"> {{ item.shortcut }} </span>
        </ng-template>
      </div>
    </div>
  </ng-template>
</ng-template>
