import { inject, Injectable, Injector } from '@angular/core';
import { UserManagerService } from '@shared/services/user-manager/user-manager.service';
import { ModelActionData } from '@shared/services/communication/shared-subjects/shared-subjects-models';
import { CardApiService, CommentApiService } from '@shared/api/api-loop/services';
import { SharedSubjects } from '@shared/services/communication/shared-subjects/shared-subjects';
import { ResourceBaseType } from '@shared/api/common/contatns/ResourceBaseType';
import { CommentDraftActions } from '@shared/modules/comments/common/constants/comment-draft-actions';
import { CommentMail } from '@shared/api/api-loop/models/comment-mail';
import { replaceContentLinksWithTempFiles } from '@shared/modules/comments/common/helpers/replace-content-links-with-temp-files';
import { CommentActionsService } from '@shared/modules/comments/components/services/comment-actions/comment-actions.service';
import { MailCommentSendMailOperationsModule } from '@shared/modules/comments/components/mail-comment/mail-comment-send-mail-operations/mail-comment-send-mail-operations.module';
import { convertAttachmentsToTemp$ } from '@shared/modules/comments/common/helpers/send-comment-helpers/convert-attachments-to-temp';
import { HandleTimeSmartCommandsApiService } from '@shared/modules/comments/data-access/handle-time-data-access/handle-time-smart-commands-api.service';
import { HandleTimeMethod } from '@shared/modules/smart-commands/common/handle-time/constants/handle-time-method';
import { SubscriptionLicense } from '@shared/api/api-loop/models/subscription-license';
import { CommentFormMailType } from '@shared/modules/comments/components/comment-form/comment-form.component';
import { map, switchMap, tap } from 'rxjs/operators';
import { Observable, of, take } from 'rxjs';
import { UntilDestroy } from '@ngneat/until-destroy';
import { StorageKey, StorageService } from '@dta/shared/services/storage/storage.service';
import { addSeconds } from 'date-fns';
import { TrackingService } from '@dta/shared/services/tracking/tracking.service';
import { TrackingConstants } from '@dta/shared/services/tracking/tracking.constants';
import { Time } from '@dta/shared/utils/common-utils';

@UntilDestroy()
@Injectable({
  providedIn: MailCommentSendMailOperationsModule
})
export class MailCommentSendMailOperationsService {
  private readonly userManagerService: UserManagerService = inject(UserManagerService);
  private readonly commentApiService: CommentApiService = inject(CommentApiService);
  private readonly injector: Injector = inject(Injector);
  private readonly commentActionsService: CommentActionsService = inject(CommentActionsService);
  private readonly storageService: StorageService = inject(StorageService);
  private readonly cardApiService: CardApiService = inject(CardApiService);
  private readonly trackingService: TrackingService = inject(TrackingService);
  private readonly time: Time = inject(Time);
  private readonly handleTimeSmartCommandsApiService: HandleTimeSmartCommandsApiService = inject(
    HandleTimeSmartCommandsApiService
  );

  private sendDraftDelete(cardDraftId?: string): void {
    if (!cardDraftId) {
      return;
    }

    let modelActionData = new ModelActionData();
    modelActionData.resource = ResourceBaseType.commentDraft;
    modelActionData.action = CommentDraftActions.draftDeleted;
    modelActionData.data = {
      draftCardId: cardDraftId
    };

    SharedSubjects._modelAction$.next(modelActionData);
  }

  createCommentMail$(
    commentParams: CommentFormMailType,
    options?: {
      shouldHandleStopDraftTime?: boolean;
      conversationId?: string;
      shouldSendImmediately?: boolean;
    }
  ): Observable<CommentMail> {
    let properties = {
      'Files': commentParams.commentMail?.attachments?.resources?.length,
      'Included Users': commentParams.commentMail?.to?.resources?.length,
      'Included CC Users': commentParams.commentMail?.cc?.resources?.length,
      'Included BCC Users': commentParams.commentMail?.bcc?.resources?.length,
      'Sending immediately': options?.shouldSendImmediately || !commentParams.draftId,
      'SendLater DateTime': commentParams.sendLaterDateTime?.toISOString(),
      'SendLater offset in seconds': !commentParams.sendLaterDateTime
        ? (commentParams.sendLaterInSeconds ?? 10)
        : undefined
    };

    if (options?.shouldSendImmediately || !commentParams.draftId) {
      if (commentParams.draftId) {
        this.sendDraftDelete(commentParams.commentDraftId);

        const handleTimeSettings = (
          this.storageService.getParsedItem(
            this.storageService.getKey(this.userManagerService.getCurrentUserEmail(), StorageKey.userTopPriorityLicense)
          ) as SubscriptionLicense
        ).handleTimeSettings;

        if (options?.shouldHandleStopDraftTime && options?.conversationId && handleTimeSettings?.isEnabled) {
          this.handleTimeSmartCommandsApiService
            .sendHandleTimeStatus$(HandleTimeMethod.stop_draft_time, {
              comment_draft_id: commentParams.draftId,
              group_id: commentParams.groupId,
              conversation_id: options.conversationId,
              is_comment_split: !commentParams.commentMail?.parent?.id
            })
            .pipe(take(1))
            .subscribe();
        }
      }

      this.trackingService.track(
        this.userManagerService.getCurrentUserEmail(),
        TrackingConstants.newMessageEventName,
        properties
      );
      return this.convertToTmpAndReplaceImgs$(commentParams.commentMail).pipe(
        switchMap(comment => {
          commentParams.commentMail = comment;
          return this.commentApiService
            .Comment_CreateCommentMail(commentParams, this.userManagerService.getCurrentUserEmail())
            .pipe(
              tap(comment => {
                this.commentActionsService.commentMailCreated$.next(comment);
              })
            );
        })
      );
    }

    const params: CardApiService.Card_SendLaterCardDraftParams = {
      cardDraftId: commentParams.draftId,
      sendDateTime: commentParams.sendLaterDateTime?.toISOString(),
      offsetInSeconds: !commentParams.sendLaterDateTime ? (commentParams.sendLaterInSeconds ?? 9) : undefined
    };

    // if (params.offsetInSeconds) {
    //   this.notificationsService.setInAppNotification(this.userManagerService.getCurrentUserEmail(), <
    //     NotificationEventEmailSending
    //   >{
    //     type: NotificationEventType.EmailSending,
    //     msg: 'Sending',
    //     delayInSeconds: params.offsetInSeconds,
    //     draftId: commentParams.draftId,
    //     commentDraftId: commentParams.commentDraftId,
    //     conversationId: commentParams.conversationId
    //   });
    // }

    this.trackingService.track(
      this.userManagerService.getCurrentUserEmail(),
      TrackingConstants.newMessageEventName,
      properties
    );

    return this.time.getServerDateTime().pipe(
      switchMap(date => {
        this.commentActionsService.commentDraftSendLaterUpdate$.next({
          id: commentParams.commentDraftId,
          sendLaterDateTime:
            commentParams.sendLaterDateTime?.toISOString() ?? addSeconds(date, params.offsetInSeconds).toISOString(),
          conversationId: commentParams.conversationId
        });

        return this.cardApiService
          .Card_SendLaterCardDraft(params, this.userManagerService.getCurrentUserEmail())
          .pipe(map(() => commentParams.commentMail));
      })
    );
  }

  private convertToTmpAndReplaceImgs$(comment: CommentMail): Observable<CommentMail> {
    if (!comment.attachments.resources?.length) {
      return of(comment);
    }

    return convertAttachmentsToTemp$(comment.attachments.resources, this.injector).pipe(
      map(attachments => {
        comment.attachments.resources = attachments;
        return comment;
      }),
      map(comment => {
        comment.body.content = replaceContentLinksWithTempFiles(
          comment.body?.content,
          comment.attachments.resources ?? []
        );
        return comment;
      })
    );
  }
}
