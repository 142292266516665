import {
  ComponentRef,
  Directive,
  ElementRef,
  EventEmitter,
  inject,
  Injector,
  Input,
  OnDestroy,
  OnInit,
  Output,
  Type,
  ViewContainerRef
} from '@angular/core';
import {
  CustomDropdownPlacement,
  CustomDropdownService
} from '@shared/ui/dropdown/custom-dropdown/custom-dropdown.service';
import { EnumObjectValue } from '@shared/utils/common/types';
import { CustomDropdownBase } from '@shared/ui/dropdown/custom-dropdown/custom-dropdown-base';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { ReplaySubject, Subscription } from 'rxjs';

export type CustomDropdownComponentConfiguration<C> = {
  component: {
    component: Type<C>;
    parentInjector?: Injector;
    module?: Type<any>;
  };
  componentParams?: Partial<C>;
};

@UntilDestroy()
@Directive({
  selector: '[loopCustomDropdown]'
})
export class DropdownTriggerDirective<C extends CustomDropdownBase<T>, T = any> implements OnInit, OnDestroy {
  private readonly elementRef: ElementRef = inject(ElementRef);
  private readonly customDropdownService: CustomDropdownService = inject(CustomDropdownService);
  private readonly viewContainerRef: ViewContainerRef = inject(ViewContainerRef);

  @Input() dropdownConfiguration: CustomDropdownComponentConfiguration<C>;

  private readonly componentParams$: ReplaySubject<Partial<C>> = new ReplaySubject<Partial<C>>(1);
  @Input() set componentParams(componentParams: Partial<C>) {
    this.componentParams$.next(componentParams);
  }
  @Input() customDropdownPlacement?: EnumObjectValue<typeof CustomDropdownPlacement>;

  @Output() selectItem: EventEmitter<T> = new EventEmitter<T>();

  private componentRef?: ComponentRef<any>;
  private observeComponentParamsSubscription?: Subscription;

  ngOnInit(): void {
    this.elementRef.nativeElement.addEventListener('click', () => {
      this.openDropdown();
    });
  }

  openDropdown(): void {
    const { componentRef, dropdownSubmit$ } = this.customDropdownService.openDropdown({
      dropdownConfiguration: this.dropdownConfiguration,
      viewContainerRef: this.viewContainerRef,
      preferredPlacement: this.customDropdownPlacement
    });
    this.componentRef = componentRef;

    this.observeComponentParams();

    dropdownSubmit$.pipe(untilDestroyed(this)).subscribe(value => {
      this.selectItem.emit(value);
    });
  }

  ngOnDestroy(): void {
    this.elementRef.nativeElement.removeEventListener('click', () => {
      this.customDropdownService.openDropdown({
        dropdownConfiguration: this.dropdownConfiguration,
        viewContainerRef: this.viewContainerRef
      });
    });
  }

  private observeComponentParams(): void {
    this.observeComponentParamsSubscription?.unsubscribe();
    this.observeComponentParamsSubscription = this.componentParams$
      .pipe(untilDestroyed(this))
      .subscribe(componentParams => {
        this.customDropdownService.setComponentParams(this.componentRef, componentParams);
      });
  }
}
