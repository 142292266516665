import { inject, Injectable, Injector } from '@angular/core';
import {
  CustomDropdownPlacement,
  CustomDropdownService
} from '@shared/ui/dropdown/custom-dropdown/custom-dropdown.service';
import { AddToFolderDropdownComponent } from '@shared/modules/folder/component/add-to-folder-dropdown/add-to-folder-dropdown.component';
import { LabelsDropdownComponent } from '@shared/modules/shared-tag/components/labels-dropdown/labels-dropdown.component';
import { MoveToTeamDropdownComponent } from '@shared/modules/teams/components/move-to-team-dropdown/move-to-team-dropdown.component';
import { SnoozeDropdownComponent } from '@shared/modules/conversations/components/conversation-header/dropdowns/snooze-dropdown/snooze-dropdown.component';
import { AssignDropdownComponent } from '@shared/modules/conversations/components/conversation-header/dropdowns/assign-dropdown/assign-dropdown.component';
import { UserManagerService } from '@shared/services/user-manager/user-manager.service';
import { moveToNextCard } from '@shared/modules/middle-view/helpers/move-to-next-card';
import { CommandService } from '@shared/services/data/command/command.service';
import { ConversationModel } from '@dta/shared/models-api-loop/conversation-card/conversation/conversation.model';
import { ContainerRef } from 'ngx-infinite-scroll';
import { TrackingService } from '@dta/shared/services/tracking/tracking.service';
import { AssignDropdownHelper } from '@dta/ui/components/common/assign-dropdown/assign-dropdown.helper';
import { CardMailModel } from '@dta/shared/models-api-loop/conversation-card/card/card.model';
import { take } from 'rxjs/operators';
import { ContactModel } from '@dta/shared/models-api-loop/contact/contact.model';

@Injectable()
export class ConversationHeaderActionService {
  private readonly customDropdownService: CustomDropdownService = inject(CustomDropdownService);
  private readonly injector: Injector = inject(Injector);
  private readonly trackingService: TrackingService = inject(TrackingService);
  private readonly userManagerService: UserManagerService = inject(UserManagerService);
  private readonly slashCommandService: CommandService = inject(CommandService);

  openAddToFolder(conversationIds: string[], groupId: string, containerRef: ContainerRef): void {
    this.trackingService.trackUserClick(
      this.userManagerService.getCurrentUserEmail(),
      'ConversationHeader',
      'Open add to folder dropdown',
      {
        conversationIds: conversationIds,
        groupId: groupId
      }
    );

    this.customDropdownService.openDropdown({
      dropdownConfiguration: {
        component: {
          component: AddToFolderDropdownComponent,
          parentInjector: this.injector
        },
        componentParams: {
          groupId: groupId,
          conversationIds: conversationIds
        }
      },
      viewContainerRef: containerRef,
      preferredPlacement: CustomDropdownPlacement.bottom_end
    });
  }

  openLabelsDropdown(conversations: ConversationModel[], containerRef: ContainerRef): void {
    this.trackingService.trackUserClick(
      this.userManagerService.getCurrentUserEmail(),
      'ConversationHeader',
      'Open labels dropdown',
      {
        conversationIds: conversations.map(conversation => conversation.cardId)
      }
    );

    this.customDropdownService.openDropdown({
      dropdownConfiguration: {
        component: {
          component: LabelsDropdownComponent,
          parentInjector: this.injector
        },
        componentParams: {
          conversations: conversations
        }
      },
      viewContainerRef: containerRef,
      preferredPlacement: CustomDropdownPlacement.bottom_end
    });
  }

  moveToTeam(conversation: ConversationModel, containerRef: ContainerRef): void {
    this.trackingService.trackUserClick(
      this.userManagerService.getCurrentUserEmail(),
      'ConversationHeader',
      'Open move to team dropdown',
      {
        conversationId: conversation.cardId
      }
    );

    this.customDropdownService.openDropdown({
      dropdownConfiguration: {
        component: {
          component: MoveToTeamDropdownComponent,
          parentInjector: this.injector
        },
        componentParams: {
          conversation: conversation
        }
      },
      viewContainerRef: containerRef,
      preferredPlacement: CustomDropdownPlacement.bottom_end
    });
  }

  snoozeConversation(conversation: ConversationModel, containerRef: ContainerRef): void {
    this.trackingService.trackUserClick(
      this.userManagerService.getCurrentUserEmail(),
      'ConversationHeader',
      'Open snooze dropdown',
      {
        conversationId: conversation.cardId
      }
    );
    this.customDropdownService.openDropdown({
      dropdownConfiguration: {
        component: {
          component: SnoozeDropdownComponent,
          parentInjector: this.injector
        },
        componentParams: {
          conversation: conversation
        }
      },
      viewContainerRef: containerRef,
      preferredPlacement: CustomDropdownPlacement.bottom_end
    });
  }

  assignConversation(
    conversation: ConversationModel,
    containerRef: ContainerRef,
    options?: {
      shouldReturnOnlySelectedUser: boolean;
    }
  ): any {
    this.trackingService.trackUserClick(
      this.userManagerService.getCurrentUserEmail(),
      'ConversationHeader',
      'Open assign dropdown',
      {
        conversationId: conversation.cardId
      }
    );

    return this.customDropdownService.openDropdown({
      dropdownConfiguration: {
        component: {
          component: AssignDropdownComponent,
          parentInjector: this.injector
        },
        componentParams: {
          conversation: conversation,
          shouldReturnOnlySelectedUser: options?.shouldReturnOnlySelectedUser
        }
      },
      viewContainerRef: containerRef,
      preferredPlacement: CustomDropdownPlacement.bottom_end
    });
  }

  assignContact(contact: ContactModel, conversation: ConversationModel): void {
    let command = '/assign ';
    let mention = `[user id="${contact.id}"]${contact.name}[/user]`;
    this.sendStatusCommand(command + mention, conversation);
  }

  sendStatusCommand(command: string, conversation: ConversationModel): void {
    this.trackingService.trackUserClick(
      this.userManagerService.getCurrentUserEmail(),
      undefined,
      AssignDropdownHelper.transformCommandToUppercaseWord(command)
    );

    if (conversation.cardId) {
      const isResolveOrUnassign = ['/resolve', '/unassign'].includes(command);
      const isMailCard = conversation.cardType === CardMailModel.type;

      this.slashCommandService
        .sendCommand(this.userManagerService.getCurrentUserEmail(), command, [conversation.cardId], isMailCard)
        .pipe(take(1))
        .subscribe(() => {});

      if (isResolveOrUnassign) {
        moveToNextCard(conversation.cardId, this.injector);
      }
    }
  }
}
