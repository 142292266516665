import { Pipe, PipeTransform } from '@angular/core';
import { DropdownItem, DropdownNestedItem } from '@shared/ui/dropdown/interfaces/dropdown-item';

@Pipe({
  name: 'asDropdownNestedItem'
})
export class AsDropdownNestedItemPipe implements PipeTransform {
  transform(item: DropdownItem): DropdownNestedItem {
    return item as DropdownNestedItem;
  }
}
