import { Injectable } from '@angular/core';
import { CardBaseModel, CardSharedModel } from '@dta/shared/models-api-loop/conversation-card/card/card.model';
import {
  CommentBaseModel,
  CommentChatModel,
  CommentMailModel,
  CommentModel
} from 'dta/shared/models-api-loop/comment/comment.model';
import { from, Observable, of, throwError, timer } from 'rxjs';
import { CommandServiceI } from './command.service.interface';
import * as _ from 'lodash';
import { SlashCommand } from '@shared/api/api-loop/models/slash-command';
import { filter, map, mergeMap, retryWhen, tap, toArray } from 'rxjs/operators';
import { SlashCommandResponse } from '@shared/api/api-loop/models/slash-command-response';
import { NotificationEventType } from '@dta/shared/models/notifications.model';
import { StateUpdates } from '@dta/shared/models/state-updates';
import {
  SharedTagAssigneeModel,
  SharedTagBaseModel,
  SharedTagStatusModel,
  StaticSharedTagIds
} from '@dta/shared/models-api-loop/shared-tag/shared-tag.model';
import { CardResolvedData } from '@shared/services/communication/shared-subjects/shared-subjects-models';
import { SharedSubjects } from '@shared/services/communication/shared-subjects/shared-subjects';
import { CardBase } from '@shared/api/api-loop/models/card-base';
import { ListOfTags } from '@shared/api/api-loop/models/list-of-tags';
import { CommentBase } from '@shared/api/api-loop/models/comment-base';
import { ListOfTagsModel } from '@dta/shared/models-api-loop/tag.model';
import { CommandApiService } from '@shared/api/api-loop/services/command-api.service';
import { NotificationsService } from '@shared/services/notification/notification.service';
import { CardService } from '@shared/services/data/card/card.service';
import { CommentService } from '@shared/services/data/comment/comment.service';
import { BaseService } from '@shared/services/data/base/base.service';
import { SynchronizationMiddlewareService } from '@shared/synchronization/synchronization-middleware/synchronization-middleware.service';
import { ConversationService } from '@shared/services/data/conversation/conversation.service';
import { ConversationModel } from '@dta/shared/models-api-loop/conversation-card/conversation/conversation.model';

@Injectable()
export class CommandService extends BaseService implements CommandServiceI {
  constructor(
    protected _syncMiddleware: SynchronizationMiddlewareService,
    private _commandApiService: CommandApiService,
    private _commentService: CommentService,
    private _cardService: CardService,
    private _notificationsService: NotificationsService,
    private _conversationService: ConversationService
  ) {
    super(_syncMiddleware);
  }

  get constructorName(): string {
    return 'CommandService';
  }

  sendCommand(
    forUserEmail: string,
    request: string,
    cardIds: string[],
    isGlobalAssign: boolean
  ): Observable<CommentModel[]> {
    if (!CommandServiceHelper.isSlashCommand(request)) {
      throw new Error('Not a slash command');
    }
    if (isGlobalAssign && cardIds.length > 1) {
      throw new Error('No batch commands on global assign');
    }
    if (_.isEmpty(cardIds)) {
      return of([]);
    }

    let command: SlashCommand = {
      $type: 'SlashCommand',
      request: request,
      resourceIds: cardIds
    };

    return this._commandApiService
      .Command_RunCommand(
        {
          command: command
        },
        forUserEmail
      )
      .pipe(
        filter((response: SlashCommandResponse) => {
          return response.updatedResources && !_.isEmpty(response.updatedResources.resources);
        }),
        mergeMap((response: SlashCommandResponse) => {
          return this.handleCommandResponse(forUserEmail, response, cardIds, isGlobalAssign);
        }),
        retryWhen((handler: Observable<any>) => {
          return handler.pipe(
            mergeMap((err, retryCount) => {
              // retry when BE not available
              if ([0, 408, 429, 502, 503, 504].includes(err.status)) {
                this._notificationsService.setInAppNotification(undefined, {
                  type: NotificationEventType.BasicNotification,
                  msg: 'Slash command failed. Trying again...'
                });
                return timer(Math.pow(2, retryCount) * 2000);
              } else {
                this._notificationsService.setInAppNotification(undefined, {
                  type: NotificationEventType.BasicNotification,
                  msg: 'Service not available. Please try again later.'
                });

                return throwError(err);
              }
            })
          );
        })
      );
  }

  private handleCommandResponse(
    forUserEmail: string,
    response: SlashCommandResponse,
    cardIds: string[],
    isGlobalAssign: boolean = false
  ): Observable<CommentModel[]> {
    let stateUpdates = new StateUpdates();
    let comments = this.filterComments(response);
    let sharedTags = this.filterSharedTags(response);
    let sharedCard = this.filterSharedCard(response);

    let obs = of(stateUpdates);

    if (isGlobalAssign) {
      // When we do global assign, we create card for response comment.
      // Created card is of type CardShared and has id same as BE id (returned in response)
      let sharedCardModel = <CardSharedModel>CardSharedModel.create(sharedCard);
      delete sharedCardModel.snapshotResource;

      comments = this.decorateParentForGlobalAssignComments(comments);

      obs = this._cardService.saveAll(forUserEmail, [sharedCardModel]);

      cardIds[0] = sharedCardModel.id;
    }

    return obs.pipe(
      /**
       * Save all updated comments
       */
      mergeMap(() => {
        return this._commentService.saveAllAndPublish(forUserEmail, comments);
      }),
      mergeMap((_comments: CommentModel[]) => {
        stateUpdates.add(_comments);

        comments = _comments;

        /** Update card */
        return this._conversationService.findOrFetchByCardIds(forUserEmail, cardIds).pipe(
          mergeMap((conversations: ConversationModel[]) => from(conversations)),
          mergeMap((conversation: ConversationModel) => {
            return this.updateConversation(forUserEmail, conversation, sharedTags, isGlobalAssign);
          }),
          tap((_stateUpdates: StateUpdates) => {
            stateUpdates.mergeWith(_stateUpdates);
          }),
          toArray()
        );
      }),
      tap(() => {
        let sharedCards = _.filter(stateUpdates.cards, card => card instanceof CardSharedModel);
        if (sharedCards && !_.isEmpty(sharedCards)) {
          let allSharedTags = _.flatMap(sharedCards, card => card.getSharedTags());
          this.setToastMessage(<SharedTagBaseModel[]>allSharedTags);
        }
      }),
      map(() => {
        return comments;
      })
    );
  }

  private updateConversation(
    forUserId: string,
    conversation: ConversationModel,
    sharedTags: ListOfTags[],
    isGlobalAssign: boolean = false
  ): Observable<StateUpdates> {
    // Remove id to make it seem like local card. It will get added when event will come
    let obj = of(conversation);

    if (!_.isEmpty(sharedTags)) {
      return obj.pipe(
        mergeMap(() => {
          return this._conversationService.updateCardsSharedTags(forUserId, sharedTags);
        }),
        mergeMap((conversations: ConversationModel[]) => {
          return this._conversationService.saveAllAndPublish(forUserId, conversations);
        }),
        map((_conversations: ConversationModel[]) => {
          return new StateUpdates(_conversations);
        })
      );
    }

    return obj.pipe(
      mergeMap(() => {
        return this._conversationService.findOrFetchByCardId(forUserId, conversation.cardId);
      }),
      mergeMap((_conversation: ConversationModel) => {
        return this._conversationService.saveAllAndPublish(forUserId, [_conversation]);
      }),
      map((_conversations: ConversationModel[]) => {
        return new StateUpdates(_conversations);
      })
    );
  }

  private filterComments(response: SlashCommandResponse): CommentModel[] {
    let types = [CommentMailModel.type, CommentChatModel.type];

    return _.filter(response.updatedResources.resources, resource => {
      return types.includes(resource.$type);
    }).map((comment: CommentBase) => {
      comment.parent = CardBaseModel.buildFromBaseAsReference(comment.parent);
      return CommentBaseModel.create(comment);
    });
  }

  private filterSharedTags(response: SlashCommandResponse): ListOfTags[] {
    return _.filter(response.updatedResources.resources, resource => {
      return resource.$type === ListOfTagsModel.type;
    });
  }

  private filterSharedCard(response: SlashCommandResponse): CardBase {
    return _.find(response.updatedResources.resources, resource => {
      return resource.$type === CardSharedModel.type;
    });
  }

  private decorateParentForGlobalAssignComments(comments: CommentModel[]) {
    return _.forEach(comments, (comment: CommentModel) => {
      comment.parent = CardBaseModel.create(comment.parent);
      return comment;
    });
  }

  private setToastMessage(sharedTags: SharedTagBaseModel[]) {
    if (_.isEmpty(sharedTags)) {
      return;
    }

    let statusTag = _.find(sharedTags, tag => {
      return tag.$type === SharedTagStatusModel.type;
    });

    let statusTagCount = _.filter(sharedTags, tag => {
      return tag.$type === SharedTagStatusModel.type;
    }).length;

    let assigneeTag = <SharedTagAssigneeModel>_.find(sharedTags, tag => {
      return tag.$type === SharedTagAssigneeModel.type;
    });

    if (assigneeTag) {
      this._notificationsService.setInAppNotification(undefined, {
        type: NotificationEventType.EmailAssigned,
        msg: 'Email assigned ' + (assigneeTag._ex ? 'to ' + assigneeTag._ex.user.name : '')
      });
      return;
    }

    switch (statusTag.id) {
      // Unassigned
      case StaticSharedTagIds.UNASSIGNED_ID:
        this._notificationsService.setInAppNotification(undefined, {
          type: NotificationEventType.EmailUnassigned,
          msg: statusTagCount > 1 ? 'All messages unassigned' : ''
        });
        break;
      // Resolved
      case StaticSharedTagIds.RESOLVED_ID:
        this._notificationsService.setInAppNotification(undefined, {
          type: NotificationEventType.EmailResolved,
          msg: statusTagCount > 1 ? 'All messages resolved' : ''
        });
        break;
      default:
        break;
    }
  }
}

export class CommandServiceHelper {
  static isSlashCommand(message: string): boolean {
    return /^\/\w/.test(message);
  }
}
