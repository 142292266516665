import {
  Component,
  ComponentRef,
  ElementRef,
  EventEmitter,
  Injector,
  Input,
  Output,
  ViewEncapsulation
} from '@angular/core';
import { DialogRef } from '@angular/cdk/dialog';
import { of, take } from 'rxjs';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { OpenDialogOptions, OverlayDialogOptions } from '@shared/ui/kit/dialog/open-dialog-options';
import { DIALOG_CONTENT, DialogContent } from '@shared/ui/kit/dialog/modal-content-element-ref';

@UntilDestroy()
@Component({
  template: `
    <ng-container *ngIf="{ title: (options.title$ | async), isClosable: options.isClosable }; let resolvedObj">
      <ng-container *ngIf="!options.hasCustomHeader">
        <ng-container *ngIf="resolvedObj.isClosable || resolvedObj.title">
          <div class="loop-dialog-panel-header" [class.full-screen]="options.isFullScreen">
            <h3>{{ resolvedObj.title }}</h3>
            <a *ngIf="resolvedObj.isClosable" (click)="close()">
              <loop-icon class="cursor-pointer" name="xmark" width="14px"></loop-icon>
            </a>
          </div>
        </ng-container>
      </ng-container>
    </ng-container>

    <div
      *ngIf="options.componentData"
      class="loop-dialog-panel-content"
      [class.limited-height]="!options.isFullScreen && !options.height"
      [class.full-height]="options.isFullScreen"
    >
      <ng-container *ngIf="browserDialogContentInjector">
        <ng-template
          [loopDialogContent]="options.componentData.component"
          [loopDialogContentModule]="options.componentData.module"
          [loopDialogContentParams]="options.componentParams"
          [loopDialogContentInjector]="browserDialogContentInjector"
          (componentRef)="componentRef.emit($event)"
        ></ng-template>
      </ng-container>
    </div>

    <div
      *ngIf="options.primaryButton && (options.primaryButton.text$ | async)"
      class="flex justify-end padding-bottom-half padding-right"
    >
      <loop-button
        [isDisabled]="(options.primaryButton.isDisabled$ | async) === true"
        [isLoading]="(options.primaryButton.isLoading$ | async) === true"
        color="systemContrast"
        fill="color"
      >
        options.primaryButton.text$ | async
      </loop-button>
    </div>
  `,
  styleUrls: ['./browser-dialog-content.scss'],
  encapsulation: ViewEncapsulation.None
})
export class BrowserDialogContentComponent<C> {
  @Input() close!: () => void;

  private _options!: OverlayDialogOptions<C>;
  // when using ModalService, OnChanges can't register Input changes, so we need to use setter to pass value to injector
  @Input() set options(options: OverlayDialogOptions<C>) {
    this._options = options;
    this.browserDialogContentInjector = Injector.create({
      providers: [
        {
          provide: DIALOG_CONTENT,
          useValue: {
            contentElementRef: this.elementRef,
            close: () => this.close(),
            setTitle: title => this.overrideTitle(title)
          } as DialogContent
        }
      ],
      parent: options.componentData?.parentInjector ?? this.injector
    });
  }

  get options(): OverlayDialogOptions<C> {
    return this._options;
  }

  @Input() dialogRef?: DialogRef<unknown, BrowserDialogContentComponent<C>>;
  @Output() componentRef: EventEmitter<ComponentRef<C>> = new EventEmitter<ComponentRef<C>>();

  browserDialogContentInjector?: Injector;

  constructor(
    private readonly injector: Injector,
    private readonly elementRef: ElementRef
  ) {}

  handleButtonClick(): void {
    const action = this.options.primaryButton?.action;
    if (!action) {
      this.close();
      return;
    }

    this.componentRef.pipe(take(1), untilDestroyed(this)).subscribe(componentRef => {
      action(componentRef.instance);
    });
  }

  overrideTitle(title: string): void {
    this.options.title$ = of(title);
  }
}
