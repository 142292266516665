import { Directive, Input } from '@angular/core';
import { MenuStack, MENU_STACK } from '@angular/cdk/menu';
import { DropdownTrigger } from '../enumerators/dropdown-trigger';
import { DropdownItem } from '../interfaces/dropdown-item';
import { DropdownBase } from './dropdown-base.directive';

@Directive({
  selector: '[loopContextDropdown]',
  providers: [{ provide: MENU_STACK, useClass: MenuStack }]
})
export class ContextDropdownDirective extends DropdownBase {
  protected override getTrigger(): DropdownTrigger {
    return DropdownTrigger.context;
  }

  @Input() set loopContextDropdown(items: readonly DropdownItem[]) {
    this.setItems(items);
  }
}
