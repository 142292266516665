import { Injectable, Optional } from '@angular/core';
import { FileService } from '@shared/services/data/file/file.service';
import { UserAuthService } from '@shared/modules/auth/auth-data-access/user-auth.service';
import { SettingsScope } from '@shared/modules/auth/settings/common/settings-scope';
import { ConversationSettingsScopeProperty } from '@shared/modules/auth/settings/common/conversation-settings-scope';
import { DialogService } from '@shared/ui/kit/dialog/dialog.service';
import { FilesPreviewDialogContentComponent } from '@shared/modules/files/components/files-preview-dialog-content/files-preview-dialog-content.component';
import { FilesPreviewDialogContentModule } from '@shared/modules/files/components/files-preview-dialog-content/files-preview-dialog-content.module';
import { File as IFile } from '@shared/api/api-loop/models/file';
import { defer, Observable, of, Subject } from 'rxjs';
import { FileModel } from '@dta/shared/models-api-loop/file.model';
import { isWebApp } from '@dta/shared/utils/common-utils';
import { CommentModel } from '@dta/shared/models-api-loop/comment/comment.model';
import { map, switchMap, take, tap } from 'rxjs/operators';
import { UntilDestroy } from '@ngneat/until-destroy';

interface OnFileIconClickParams {
  forUserEmail: string;
  file: IFile;
  comment?: CommentModel;
  isSyncing?: boolean;
  files?: IFile[];
}

@UntilDestroy()
@Injectable()
export class AttachmentService {
  constructor(
    private _fileService: FileService,
    private dialogService: DialogService,
    @Optional() private readonly userAuthService: UserAuthService
  ) {}

  protected readonly shouldOpenAttachmentsInNativeApps$: Observable<boolean> = defer(() => {
    if (!this.userAuthService) {
      return of(true);
    }

    return this.userAuthService.currentSession$.pipe(
      map(
        session =>
          !!session?.userConfiguration?.[SettingsScope.conversation]?.[
            ConversationSettingsScopeProperty.should_open_attachments_in_native_apps
          ]
      )
    );
  });

  downloadAttachment(
    forUserEmail: string,
    file: IFile,
    saveToDownloads?: boolean,
    openInNativeAfterDownload?: boolean
  ): Observable<'in-progress' | 'done'> {
    let progressUpdates$ = new Subject<'in-progress' | 'done'>();

    let showLoaderTimeoutId: NodeJS.Timeout;
    if (saveToDownloads) {
      showLoaderTimeoutId = setTimeout(() => {
        if (!progressUpdates$.closed) {
          progressUpdates$.next('in-progress');
        }
      }, 500);
    }

    this._fileService
      .downloadFile(forUserEmail, new FileModel(file), saveToDownloads, openInNativeAfterDownload)
      .pipe(
        take(1),
        tap(() => {
          clearTimeout(showLoaderTimeoutId);
          progressUpdates$.next('done');
          progressUpdates$.complete();
        })
      )
      .subscribe();

    return progressUpdates$.asObservable();
  }

  onFileIconClick({ forUserEmail, file, comment, files }: OnFileIconClickParams): void {
    if (!file) {
      return;
    }

    this.shouldOpenAttachmentsInNativeApps$
      .pipe(
        take(1),
        switchMap(shouldOpenAttachmentsInNativeApps => {
          // If not a local file, download first (DTA only)
          if (!isWebApp() && shouldOpenAttachmentsInNativeApps) {
            return this.downloadAttachment(forUserEmail, file, true, true);
          }

          // const fileExtension = getFileExtension(file.name);
          // if (
          //   !isWebApp() &&
          //   file._ex?.path &&
          //   ([HandledFileType.eml, HandledFileType.msg] as string[]).includes(fileExtension)
          // ) {
          //   this._electronService.ipcRenderer.send(IPC.OPEN_MAIL_FILE, {
          //     path: file._ex.path.replace('file://', ''),
          //     extension: fileExtension
          //   });
          //   return of(undefined);
          // }

          // Open in file preview (WEB)
          const filesToOpen = files || comment?.getNonInlineAttachments() || [file];
          if (file.id) {
            return this.dialogService.openDialog({
              title$: of(file.name),
              isFullScreen: true,
              hasCustomHeader: true,
              componentData: {
                component: FilesPreviewDialogContentComponent,
                module: FilesPreviewDialogContentModule
              },
              componentParams: {
                files: filesToOpen,
                currentFileIndex: filesToOpen.findIndex(fileToOpen => fileToOpen.id === file.id)
              },
              width: '100%'
            });
          }
          return of(undefined);
        }),
        take(1)
      )
      .subscribe();
  }
}
